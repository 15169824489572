@import "@netapp/bxp-design-system-react/styles/colors";
@import "../../../styles/constants.module";

.content {
  display: flex;
  flex-direction: column;
  width: $page-content-width-large;
  margin: 0 auto;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $primary-blue;
  margin-top: 40px;
  padding-bottom: 12px;
  border-bottom: 1px solid $disabled-grey;
  white-space: nowrap;

  &[class*="not-spaced"] {
    justify-content: flex-start;
  }

  [class*="vol-name"] {
    margin-left: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
