@import "src/constants";

.base {
  position: sticky;
  top: $app-header-height;
  width: 100%;
  z-index: 4998;
  height: $new-secondary-header-height;
  background-image: url(./banner.jpg);

  h1 {
    text-align: center;
    font-size: 44px;
    margin: 0;
    font-weight: 600;
    color: #fff;
    position: relative;
    width: 100%;

    @media(max-width: $break-small) {
      font-size: 36px;
    }
  }
}

.content {
  margin: 0 auto;
  max-width: 1135px;
  position: relative;
  width: calc(100% - 100px);
  display: flex;
  align-items: center;
  height: 100%;
}
