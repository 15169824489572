@import "@netapp/bxp-design-system-react/styles/colors";
@import "../../styles/constants.module";

.footer {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $disabled-grey;
  border-top: 1px solid $disabled-grey;

  button {
    width: 184px;
    margin: 0 auto;
  }
}

.header {
  position: relative;
}

.header-title {
  color: $primary-blue-hover;
  position: absolute;
  left: 110px;
}

.flavour-title {
  color: $primary-blue-hover;
  margin: 0 auto;
  font-weight: lighter;
}

.support-btn {
  position: absolute;
  right: 80px;
  display: flex;
  align-items: center;
  color: $primary-blue;
  background-color: transparent;

  svg {
    margin-right: 9px;
  }
}

.main-content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
          "creds info";
  width: 900px;
  margin: 50px auto 0 auto;

  .from-area {
    grid-area: creds;
    justify-self: end;
    .from-content {
      border-right: 1px solid $disabled-grey;
      padding-right: 50px;
    }
  }

  .info-area {
    grid-area: info;
    .info-content {
      display: flex;
      flex-direction: column;
      margin-left: 50px;
    }
  }

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    height: 37px;
    .service-logo {
      width: 37px;
      height: 37px;
      margin: 0 10px 0 -7px;
    }
    .title-text {
      color: $primary-blue;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $disabled-grey;
  border-top: 1px solid $disabled-grey;

  button {
    width: 184px;
    margin: 0 auto;
  }
}
