@import "../styles/constants.module";
@import "@netapp/bxp-design-system-react/styles/colors";

.container {
  background-color: $white-grey;
}

.content {
  width: $header-content-width-large;
  margin: 30px auto 0 auto;
}

.no-volumes-watermark {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: calc(50vh - 200px);

  font-size: 18px;
  color: $disabled-grey;

  svg {
    transform: scale(1.6);
    margin-bottom: 24px;
    width: 28px;
    height: 38px;

    * {
      fill: $disabled-grey;
    }
  }
}
