@import "@netapp/bxp-design-system-react/styles/colors.scss";
@import "constants.module.scss";

:global(body) {
  // header support icon
  .support-icon {
    width: 31px;
    height: 28px;
    :global .primary-fill {
      fill: $primary-blue;
    }
    :global .primary-stroke {
      fill: $primary-blue;
    }
  }

  // error message
  .error-area {
    position: fixed;
    width: 819px;
    left: 50%;
    transform: translateX(-50%);
    bottom: $cvs-footer-height + 42px;
  }

  // common form filed input
  .form-field {
    width: 360px;
    margin-bottom: 0;
  }

  // radio button with no margin
  .radio-button {
    input {
      margin-right: 0;
    }
  }

  // dynamic list items form
  .dynamic-list-form {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: $text-grey;

    .add-button {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      border-top: solid 1px $input-field-border;
      padding-top: 15px;
      align-self: flex-start;
      width: 100%;

      button {
        background-color: transparent;
        display: flex;
        align-items: center;
        padding: 0;

        span {
          white-space: nowrap;
          color: $text-grey;
        }

        svg {
          margin: 0 7px 0 0;
          :global .primary-fill {
            fill: $primary-blue;
          }
        }
      }
    }
  }

  // in line button
  .in-text-button {
    color: $primary-blue;
    padding: 0;
    margin: 0;
    background-color: transparent;
  }
}




