$cvs-footer-height: 69px;
$cvs-header-height: 75px;
$page-content-width-large: 780px;
$header-content-width-large: 1280px;
$button-width: 152px;
$button-large-height: 40px;
$button-small-height: 32px;
$popover-inactive: #8589ac;
$popover-active: #45486c;
$brownish-grey: #666666;
$divider-grey: #d7d7d7;
$light-grey: #979797;
$greyish-white: #f5f5f5;
$mid-blue: #2359a3;
$vol-card-back: #f1f9fd;
