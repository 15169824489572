@import "@netapp/bxp-design-system-react/styles/colors";
@import "../../styles/constants.module";

.content-layout {
  display: flex;
  flex-direction: column;
  width: 760px;
  margin: 0 auto;
  padding-bottom: 35px;

  .title {
    margin: 33px 0 33px 0;
    font-weight: 300;
    color: $primary-blue-hover;
    text-align: center;
  }
}

.footer {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $disabled-grey;
  border-top: 1px solid $disabled-grey;

  button {
    width: 184px;
    margin: 0 auto;
  }
}

.tabs {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 30px;
  margin: 0 auto;
}

.header-title {
  position: absolute;
  left: 110px;
}
