@import './constants';

$purple: #c924f1;
$teal: #5eddc4;
$yellow: #fdc106;

$headerMenuZ: 5002;

@mixin transparent-button() {
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

@mixin standard-grid() {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

@mixin new-standard-view($padding: 60px) {
  padding-top: $new-secondary-header-height + $padding;
  position: relative;

  @media(max-width: 1366px) {
    padding-top: $new-secondary-header-height-small + $padding;
  }
}

@mixin getting-started-h3() {
  font-size: 25px;
  color: #1e4a93;
  font-weight: 600;
  margin: 0 0 40px;
  text-align: center;
}

@mixin bullets-ul {
  li {
    list-style-type: none;
    line-height: 2;
    padding-left: 25px;
    margin-bottom: 10px;
    svg {
      margin-left: -25px;
      margin-right: 17px;
    }
  }
}

@mixin standard-view {
  width: 1135px;
  margin: 0 auto;
}

@mixin standard-modal($width: 700px, $height: 505px) {
  .mask {
    background-color: rgba(232, 232, 232, 0.7);
  }

  .modal {
    @if ($width){
      width: $width;
      height: $height;

      top: calc(50% - #{$height / 2});
      left: calc(50% - #{$width /2});

      @media(max-height: #{$height + 80px}) {
        height: 80%;
        top: 10%;
      }
    } @else {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 600px;

      @media(max-height: 505px){
        height: 80%;
      }
    }

    background-color: $white-background;
    box-shadow: 2px 2px 4px 0 rgba(99, 99, 99, 0.5);
    border-radius: 4px;

    > div {
      height: 100%;
    }
  }

  header {
    height: 65px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: $white-background;
    padding-top: 22px;
    padding-left: 60px;
    font-size: 20px;
    line-height: 1.25;
    color: $primary-blue;
    box-shadow: 2px 2px 5px 0 rgba(187, 187, 187, 0.5);
    position: relative;
    z-index: 1;

    button {
      border: none;
      padding: 0;
      margin: 0;
      position: absolute;
      top: 27px;
      right: 25px;
      background-color: transparent;
    }
  }

  p {
    font-size: 14px;
    color: $grey-6;
    line-height: 26px;
    margin-bottom: 13px;

    a{
      color: $primary-blue;
    }
  }

  .dialog-content {
    padding: 30px 40px;
    overflow-y: auto;
    height: calc(100% - 140px);
    scrollbar-color: #ccc #eee;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 7.5px;
    };

    &::-webkit-scrollbar-track  {
      background-color: #eee;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
  }

  .dialog-button {
    float: right;
    width: 128px;
    height: 40px;
    border-radius: 4px;
    background-color: $primary-blue;
    box-shadow: 1px 1px 1px 0 rgba(99, 99, 99, 0.5);
    font-size: 15px;
    line-height: 2.13;
  }

  .button-row {
    position: absolute;
    bottom: 25px;
    left: 40px;
    right: 40px;
    button {
      float: right;
      width: 128px;
      height: 40px;
      border-radius: 4px;
      background-color: $primary-blue;
      box-shadow: 1px 1px 1px 0 rgba(99, 99, 99, 0.5);
      font-size: 15px;
      line-height: 2.13;
    }
  }
}
