@import "@netapp/bxp-design-system-react/styles/colors";
@import "../../../../styles/constants.module";

.title {
  border-bottom: 1px solid transparent;
}

.volume-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
          "overviewTitle capacityTitle"
          "state      volSize"
          "location   usage"
          "account    poolName"
          "volLevel   poolSize"
          "protocol   .";
  row-gap: 0;
  column-gap: 83px;
  margin-top: 30px;
  font-size: 14px;
  color: $text-grey;
  font-weight: normal;
}

.grid-item {
  display: flex;
  border-bottom: 1px solid $disabled-grey;
  padding: 16px 0;
}

.overview-title {
  grid-area: overviewTitle;
}

.capacity-title {
  grid-area: capacityTitle;
}

.state {
  grid-area: state;
}

.region {
  grid-area: location;
}

.account {
  grid-area: account;
}

.level {
  grid-area: volLevel;
}

.protocol {
  grid-area: protocol;
}

.size {
  grid-area: volSize;
}

.usage {
  grid-area: usage;
}

.pool-name {
  grid-area: poolName;
}

.pool-size {
  grid-area: poolSize;
}

.info-label {
  width: 50%;
  color: $light-grey;
  white-space: nowrap;
}

.data {
  width: 50%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 40px;
}
