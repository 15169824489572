@import "./../Variables.scss";

.component-container {
  max-width: 1135px;
  margin: 0 auto;
  width: calc(100% - 100px);
}

.component-header {
  height: 58px;
}


h2 {
  font-size: 30px;
  font-weight: 600;
  line-height: 22px;
  color: $text-dark-grey;
  margin: 0 0 61px;
}

h3{
  font-size: 22px;
  font-weight: normal;
  margin: 0;
  color: $text-dark-grey;
}

button.transparent {
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

button.a {
  color: $primary-blue;
  padding: 0;
  margin: 0;
  background-color: transparent;
  font-weight: 600;
}

button.outline {
  border: 1px solid $primary-blue;
  color: $primary-blue;
  background-color: #fff;
  transition: all 0.3s;
  border-radius: 5px;

  &:hover {
    background-color: $primary-blue;
    color: #fff;
  }
}

.spinner-container{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 160px;

  $offset: 187;
  $duration: 1.4s;

  .spinner {
    animation: rotator $duration linear infinite;
  }

  @keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
  }

  .path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation:
            dash $duration ease-in-out infinite,
            colors ($duration*4) ease-in-out infinite;
  }

  @keyframes colors {
    0% { stroke: #777777; }
    25% { stroke: #9b9999; }
    50% { stroke: #afaeae; }
    75% { stroke: #bcbaba; }
    100% { stroke: #ccc9c9; }
  }

  @keyframes dash {
    0% { stroke-dashoffset: $offset; }
    50% {
      stroke-dashoffset: $offset/4;
      transform:rotate(135deg);
    }
    100% {
      stroke-dashoffset: $offset;
      transform:rotate(450deg);
    }
  }
}