@import "@netapp/bxp-design-system-react/styles/colors";

.action-buttons {
  display: flex;
  align-items: center;

  .support-btn {
    display: flex;
    align-items: center;
    color: $primary-blue;
    background-color: transparent;
    padding: 0 20px 0 20px;

    svg {
      margin-right: 9px;
    }
  }
}


