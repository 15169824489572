@import "./Variables.scss";

//---button sizes---
.button.large, button.large {
  width: 250px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}

.button.medium, button.medium {
  width: 160px;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
}


//colors
.button.primary, button.primary {
  background-color: $primary-blue;
}
.button.secondary, button.secondary {
  background-color: $secondary-blue;
}
.button.cancel, button.cancel{
  background-color: $text-dark-grey;
}
.button.cancel-light, button.cancel-light{
  background-color: $light-grey;
  color: #000;
}

