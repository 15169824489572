@import "@netapp/bxp-design-system-react/styles/colors";

.radio-button-card {
  box-shadow: 2px 2px 6px 0 rgba(187, 187, 187, 0.5);
  height: 55px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 14px;
  color: $text-grey;
}

.radio-button-widget {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 14px;
  color: $text-grey;
}

.radio-button-widget-title {
  font-weight: 600;
  flex: 0 1 auto;
  margin-right: 20px;
}

.radio-button-widget-title-card {
  margin: 0 20px 0 40px;
}

.radio-buttons {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  > label:not(:first-child) {
    margin-left: 37px;
  }
}
