.dialog{
  position: fixed;
  z-index: 200000000;

  .mask{
    position: fixed;
    z-index: 200000000;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.55;
    background-color: #2d2a2b;
  }

  .modal{
    position: fixed;
    z-index: 200000001;
    background-color: #fff;
    overflow: hidden;
  }
}