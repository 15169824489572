@import "@netapp/bxp-design-system-react/styles/colors";

:global(body) {
  .container {
    width: 800px;
  }

  .content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
            "details  tags";
  }

  .tags, .details {
    width: 360px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-row-gap: 31px;
  }

  .tags {
    grid-area: tags;
    justify-self: end;
  }

  .details {
    grid-area: details;
  }

  .name-and-size {
    display: flex;
    margin-bottom: 35px;
  }

  .tag-form {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 35px;

    button {
      padding: 0;
      margin: 0;
      position: absolute;
      right: -40px;
      top: 50%;
      background: transparent;

      svg {
        width: 20px;
        height: 20px;
        :global .primary-fill {
          fill: $disabled-grey;
        }
      }
    }
  }

  .volume-name {
    width: 266px;
    margin-right: 10px;
  }

  .volume-size {
    width: 84px;
  }

  .vnet-select, .region {
    margin-bottom: 35px;
  }

  .tag-key, .tag-value {
    width: 170px;
  }

  .tag-key {
    margin-right: 20px;
  }
}

