:global(body) {
  .content {
    width: 500px;
  }

  .notification {
    margin-top: 30px;
    background-color: #fff;
  }

  .form-field {
    margin-bottom: 0;
  }

}
