.copy-bubble {
  position: fixed;
  top: 0;
  left: 0;

  :global {
    .primary-fill {
      fill: #fff;
    }

    .primary-stroke {
      stroke: #0C1558;
    }
  }

  span {
    position: absolute;
    color: #0C1558;
    font-size: 12px;
    left: 6px;
    top: 7px;
  }
}