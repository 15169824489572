.refresh-token-generator{

  .center{
    margin: auto;
    width: 50%;
    padding: 40px 0;
  }

  .center-button {
    margin: 0 auto;
    display: block;
  }

  h3 {
    margin-bottom: 5px;
  }

  h4{
    margin: 6px 0 10px 0;
  }
}
