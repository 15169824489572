@import "@netapp/bxp-design-system-react/styles/colors";
@import "../../../../styles/constants.module";

.container {
  background-color: $white-grey;
}

.content {
  width: $header-content-width-large;
  margin: 30px auto 0 auto;
}

.actions-and-info {
  display: flex;
  justify-content: space-between;

  .info {
    color: $brownish-grey;
    font-size: 14px;
  }

  .search-and-create {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;

    .create-btn {
      margin-left: 30px;
      width: 180px;
    }
  }
}

.search-widget {
  top: 0;
}

.no-volumes-watermark {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: calc(50vh - 200px);

  font-size: 18px;
  color: $divider-grey;

  svg {
    transform: scale(1.6);
    margin-bottom: 24px;
    width: 28px;
    height: 38px;

    * {
      fill: $divider-grey;
    }
  }
}

.gallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 80px;
  row-gap: 80px;
  margin-top: 30px;
  margin-bottom: 50px;
}
