@import "./../../Variables.scss";

.base {
  min-height: calc(100vh - #{$header-height + $bottom-padding});
  position: relative;
  padding-bottom: 50px;
}

.has-footer{
  padding-bottom: 211px;
}

.header {
  width: 100%;
  position: sticky;
  top: $header-height;
  z-index: 4998;
  height: $new-secondary-header-height;
  box-shadow: 2px 2px 6px 0 rgba(187, 187, 187, 0.5);

  @media(max-width: 1366px) {
    height: $new-secondary-header-height-small;
  }

  &:global {
    &.category-storage {
      background-image: linear-gradient(to bottom, $category-storage-secondary, #ffffff);
    }

    &.category-services {
      background-image: linear-gradient(to bottom, $category-services-secondary, #ffffff);
    }

    &.category-controls {
      background-image: linear-gradient(to bottom, $category-storage-controls, #ffffff);
    }

    &.category-analytics {
      background-image: linear-gradient(to bottom, $category-services-analytics, #ffffff);
    }
  }

  :global .header-container {
    margin: 0 auto;
    width: 1135px;
    position: relative;

    @media(max-width: 1366px) {
      width: 1000px;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.7);
  }

  svg circle {
    display: none;
  }

  h1 {
    text-align: center;
    font-size: 40px;
    margin: 0;
    line-height: $new-secondary-header-height;
    font-weight: 600;
    color: #1e4a93;
    position: relative;

    @media(max-width: 1366px) {
      line-height: $new-secondary-header-height-small;
    }
  }
}

.cta {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.35;
  color: #fff;
  padding: 9.5px 30px;
  border-radius: 2px;
  box-shadow: 2px 2px 3px 0 rgba(132, 132, 132, 0.5);
  background-color: $primary-blue;
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translate(-50%, 0);
}

.cta-no-center {
  composes: cta;
  display: inline-block;
  transform: initial;
  left: initial;
  bottom: initial;
  position: relative;
  width: 240px;
  text-align: center;

  &:first-child {
    margin-right: 14px;
  }
}

.cta-span {
  position: absolute;
  bottom: -24px;
  left: 50%;
  transform: translate(-50%, 0);
}

$box-width-large: 365px;
$box-width-medium: 305px;
$box-width-small: 265px;

.section {
  text-align: center;
  margin-top: 35px;

  :global .getting-started-guide {
    color: $primary-blue;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 50px;
    display: inline-block;
  }

  h3 {
    @include getting-started-h3();
  }
}

.grid-row {
  @include standard-grid();
}

.getting-started-box {
  width: $box-width-large;
  @extend %standard-card;
  text-align: center;
  padding: 44px 20px 25px;

  @media(max-width: $break-medium) {
    width: $box-width-medium;
  }

  @media(max-width: $break-small) {
    width: $box-width-small;
  }

  &:not(:last-child) {
    margin-right: 14px;
  }

  svg {
    margin-bottom: 18px;
    height: 38px;
  }

  h4 {
    font-size: 17px;
    font-weight: 600;
    color: $primary-blue;
    margin: 0 0 12px;
  }

  p {
    display: inline-block;
    line-height: 1.8;
    color: $grey-5;
    font-size: 15px;
  }
}

.getting-started-box-no-text{
  height: 215px;
  padding-top: 46px;

  svg {
    margin-bottom: 18px;
    height: 53px;
    width: 53px;
  }

  h4 {
    line-height: 1.76;
  }
}

.getting-started-box-x4 {
  width: $box-width-large - 40px;

  @media(max-width: $break-medium) {
    width: $box-width-medium - 40px;
  }

  @media(max-width: $break-small) {
    width: $box-width-small - 40px;
  }
}

.footer {
  position: absolute;
  bottom: -$bottom-padding;
  height: 121px;
  width: 100%;
  background-color: #fff;
  text-align: center;

  h4 {
    font-size: 14px;
    color: #1e4a93;
    text-align: center;
    margin: 30px auto 20px;
  }

  button {
    @include transparent-button();
  }

  a, button {
    font-size: 15px;
    color: $grey-5;
    line-height: 17px;
    padding-right: 10px;
    margin-right: 10px;

    &:not(:last-child) {
      border-right: 1px solid $grey-5;
    }
  }
}

.intro-text {
  font-size: 17px;
  color: $grey-6;
  line-height: 1.8;
  max-width: 900px;
  margin: 0 auto 50px;
}

.no-content-intro-text {
  composes: intro-text;
  margin-top: 100px;
}

.cloud-sync-dashboard {
  width: 1135px;
  margin: auto;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-gap: 20px;
  padding-bottom: 60px;

  .chart {
    margin-left: -24px;

    :global .cloudsync-chart-xaxis-title {
      fill: rgba(#1e4a93, 0.9);
    }
  }
}

.error-message {
  margin: 20px auto 30px auto;
  border-radius: 2px;
  line-height: 2.29;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 57px;
  width: max-content;
  background-color: #FF9B9B;
  color: white;
}

.data-card {
  text-align: initial;
  box-shadow: 2px 2px 6px 0 rgba(187, 187, 187, 0.5);
  background-color: #fbfbfb;

  .card-header {
    padding: 0 40px;
    height: 69px;
    line-height: 69px;
    box-shadow: 1px 1px 3px 0 rgba(187, 187, 187, 0.5);
    background-color: #f9f9f9;
    font-size: 14px;
    font-weight: 600;
    color: #1e4a93;
  }

  .card-body {
    padding: 20px 40px 20px 40px;
  }

  .datum-title {
    margin-bottom: 20px;
    opacity: 0.9;
    font-size: 13px;
    line-height: 1.69;
    color: #1e4a93;
  }
}

.full-row-card {
  grid-column-start: 1;
  grid-column-end: 3;
}

.service-integrations {
  display: flex;

  div {
    flex-grow: 1;
  }
}

.datum-loading {
  @include loading-mask(115px);
  display: inline-block;
  vertical-align: bottom;
  height: 20px;
  margin-left: 15px;
  position: relative;
  top: -8px;
}

.datum {
  display: inline-block;
  height: 49px;
  svg {
    height: 32px;
    width: 32px;
  }

  .figure {
    margin-left: 15px;

    font-size: 36px;
    line-height: 36px;
    font-weight: 300;
    color: #434957;


  }

  .unit {
    margin-left: 4px;
    font-size: 13px;
    font-weight: 600;
    color: #434957;
  }

  & + .datum {
    margin-left: 60px;
  }
}

.protocols {
  display: flex;
  justify-content: space-around;
}

.protocol {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 125px;


  &.disabled {
    :global svg { //TODO: this should be refactored
      .background, .path-0 {
        fill: #ffffff;
      }

      .path-1, .path-2, .path-3, .path-4, .path-5, .path-6, .path-7, .name {
        fill: #b7b7b7;
      }

      .stroke-1 {
        stroke: #b7b7b7;
      }
    }

    .protocol-name {
      color: #7b7b7b;
    }
  }

  svg {
    height: 60px;
  }

  .count-badge {
    position: absolute;
    top: 33px;
    left: 76px;
    width: 21px;
    height: 21px;
    line-height: 21px;
    background-color: #1e4a93;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 50%;
    text-align: center;
    box-shadow: 2px 2px 6px 0 rgba(187, 187, 187, 0.8);
  }

  .protocol-name {
    font-size: 13px;
    line-height: 1.69;
    color: #163f68;
  }
}

.cloud-volumes-service-custom {
  margin-top: 45px;
  text-align: center;

  h5 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.7;
    color: $lochmara-blue;
    max-width: 960px;
    margin: 60px auto 70px;
  }

  h3 {
    @include getting-started-h3();
    margin-top: 60px;
  }
}

.cloud-volumes-service-switcher-container {
  display: flex;
  width: 936px;
  justify-content: space-around;
  margin: 0 auto;
}

.cloud-volumes-service-switcher {
  width: calc(33% - 20px);
  height: 152px;
  box-shadow: 2px 2px 5px 0 rgba(187, 187, 187, 0.5);
  background-color: #fbfbfb;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  &:hover{
    background-color: #e8f2f8;
  }

  svg {
    height: 30px;
    max-width: 162px;
  }
}

.cvs-active {
  composes: cloud-volumes-service-switcher;
  background-color: #e8f2f8;
}

.cloud-volumes-service-container{
  padding-top: 65px;
  p {
    font-size: 17px;
    line-height: 2;
    color: #434957;
    max-width: 900px;
    margin: 0 auto 40px;
  }
}

.subscription-status {
  text-align: center;
  margin-bottom: 25px;
  font-size: 15px;
  font-weight: 600;
  color: #434957;
  margin-top: 35px;
}