@import "@netapp/bxp-design-system-react/styles/colors";

:global(body) {
  .content {
    width: 510px;
  }

  .info {
    margin-top: 14px;
  }

  .form-field {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .button-container {
    margin-top: 0;
  }
}
