.popover-trigger-container{

}

.popover-container{
  .popover {
    position: fixed;
    z-index: 200000001;
    opacity: 1;
    transition: all 0.4s cubic-bezier(.25,.8,.25,1);

    &.enter{
      opacity: 0;
    }

    .arrow {
      position: absolute;
      bottom: -18px;
      border-left: 15.5px solid transparent;
      border-right: 15.5px solid transparent;
      border-bottom: 15px solid #fff;
    }
  }
}