@import "./../../Variables.scss";

.base {
  position: relative;
  float: right;
  top: 6px;
  height: 100%;
  width: 23px;
  transition: width 0.4s cubic-bezier(.25,.8,.25,1);
  overflow: hidden;
  max-width: 50%;

  :global {
    ::-webkit-input-placeholder { color:#b7b7b7; font-weight: lighter;}
    ::-moz-placeholder { color:#b7b7b7; font-weight: lighter;}
    :-ms-input-placeholder { color:#b7b7b7; font-weight: lighter;}
    input:-moz-placeholder { color:#b7b7b7; font-weight: lighter;}
  }

  &:global {
    &.is-open {
      width: 427px;
      max-width: 50%;
      border-bottom: 1px solid $primary-blue;

      svg {
        fill: $primary-blue;
        .primary-fill {
          fill: $primary-blue;
        }
      }

      .close {
        display: block;
      }
    }
  }

  :global .close {
    display: none;
    position: absolute;
    top: 12px;
    right: 0;
    width: 15px;
    height: 15px;

    svg{
      width: 15px;
      height: 15px;
    }
  }

  :global .open {
    position: absolute;
    left: 4px;
    top: 8px;
    width: 18px;
    height: 18px;

    svg{
      height: 18px;
      width: 18px;
    }
  }

  svg {
    fill: $grey-6;
    position: absolute;
    left: 0;
    top: 0;
  }

  input {
    width: calc(100% - 56px);
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    background-color: transparent;
    color: $grey-6;
    outline: none;

    position: absolute;
    top: 7px;
    left: 23px;
  }

  button {
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
  }
}