@import 'src/constants';

.base {
  height: $app-header-height;
  padding-left: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $headerZ;
  background-color: #034c90;
  box-shadow: 0 0 2px 0 #000000;

  .logo {
    position: relative;
    top: 9px;
    --icon-primary: #fff;
  }
}

.marketing{
  padding-left: 0 !important;
  padding-right: 0 !important;
  box-shadow: none;
}

