input, textarea, select, button {
  font-family: 'Open Sans', sans-serif;
}

body.marketing-mode{
  overflow: hidden;
}

.transparent-hide {
  opacity: 0;
}

