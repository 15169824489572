@import "@netapp/bxp-design-system-react/styles/colors";
@import "../styles/constants.module";

.back {
  position: fixed;
  top: $cvs-header-height + 33px;
  left: 110px;
  display: flex;

  button {
    padding: 0;
    margin: 0;
    background: transparent;
  }

  svg {
    transform: rotate(270deg);
    margin-right: 10px;
    width: 25px;
    height: 25px;
    :global {
      .primary-fill {
        fill: $text-grey;
      }
    }
  }
}
