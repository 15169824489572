.multi-checkbox-select__menu-list {
  scrollbar-color: #ccc #eee;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
  };

  &::-webkit-scrollbar-track  {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
  }
}

.multi-checkbox-select__multi-value__remove {
  &:hover {
    background-color: #2359A3 !important;
  }
}