@import "@netapp/bxp-design-system-react/styles/colors";
@import "../../../../styles/constants.module";

:global(body) {
  .card {
    width: 600px;
    height: 270px;
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #eeeeee;
    background-color: #ffffff;
    padding: 17px 26px;
    color: $text-grey;

    &:hover:not([disabled]) {
      box-shadow: 4px 4px 15px 0 rgba(187, 187, 187, 0.7);
    }
    &:hover {
      .front-layout .front-header .actions .actions-btn{
        visibility: visible;
        display: flex;
      }
    }
    &.flipped {
      background-color: $vol-card-back;
    }
  }

  .front-layout, .back-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content auto;
    grid-template-areas:
            "head head"
            "load load"
            "info capacity";
    row-gap: 10px;
    height: 100%;
  }

  .back-layout {
    grid-template-areas:
            "head     head"
            "load     load"
            "actions1 actions2";
  }

  .actions1, .actions2 {
    margin-top: 12px;
    &.disabled-action {
      opacity: 0.5;
    }
    .action {
      display: flex;
      align-items: center;
      background-color: transparent;
      font-size: 13px;
      margin-right: 30px;
      margin-bottom: 26px;
      color: $primary-blue;

      svg {
        width: 25px;
        height: 25px;
        margin-right: 9px;
        [class*="primary-fill"] {
          fill: $primary-blue;
        }
        [class*="primary-stroke"] {
          stroke: $primary-blue;
        }
      }

      &:hover {
        color: $primary-blue-hover;
        svg {
          [class*="primary-fill"] {
            fill: $primary-blue-hover;
          }
          [class*="primary-stroke"] {
            stroke: $primary-blue-hover;
          }
        }
      }
    }
  }

  .actions1 {
    grid-area: actions1;
    border-right: solid 1px $disabled-grey;

  }

  .actions2 {
    grid-area: actions2;
    padding-left: 20px;
  }

  .loader {
    grid-area: load;
    border-top: solid 1px $disabled-grey;
  }

  .front-header, .back-header {
    grid-area: head;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .details {
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }

      .name {
        max-width: 280px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #666;
      }
    }

    .actions {
      display: flex;
      align-items: center;

      .status {
        margin-right: 6px;
      }

      .actions-btn {
        display: none;
        background: transparent;
        align-items: center;
        font-size: 13px;
        padding: 6px 0;
        svg * {
          fill: $primary-blue
        }
      }
    }
  }

  .back-header {
    .actions {
      .vol-info, .vol-delete, .flip-back {
        display: flex;
        align-items: center;
        background-color: transparent;
        font-size: 13px;
        margin-right: 30px;
        color: $primary-blue;

        svg {
          width: 25px;
          height: 25px;
          margin-right: 9px;
          [class*="primary-fill"] {
            fill: $primary-blue;
          }
          [class*="primary-stroke"] {
            stroke: $primary-blue;
          }
        }

        .flip-back-icon {
          width: 16px;
          height: 16px;
          margin-right: 0;
        }

        &:hover {
          color: $primary-blue-hover;
          svg {
            [class*="primary-fill"] {
              fill: $primary-blue-hover;
            }
            [class*="primary-stroke"] {
              stroke: $primary-blue-hover;
            }
          }
        }
      }

      .flip-back {
        margin-right: 0;
      }
    }
  }

  .info {
    grid-area: info;
    position: relative;
    border-right: solid 1px $disabled-grey;
    margin-top: 10px;
    display: grid;
    font-size: 12px;
    row-gap: 12px;
    grid-template-rows: min-content min-content min-content min-content;
    grid-template-areas:
            "title    title"
            "sLevel   sLevelInfo"
            "sRegion  sRegionInfo"
            "protocol protocolInfo";

    .title {
      grid-area: title;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .sLevel {
      grid-area: sLevel;
      font-weight: 300;
      color: $light-grey;
    }

    .sLevelInfo {
      grid-area: sLevelInfo;
      font-weight: 600;
    }

    .sRegion {
      grid-area: sRegion;
      font-weight: 300;
      color: $light-grey;
    }

    .sRegionInfo {
      grid-area: sRegionInfo;
      font-weight: 600;
    }

    .protocol {
      grid-area: protocol;
      font-weight: 300;
      color: $light-grey;
    }

    .protocolInfo {
      grid-area: protocolInfo;
      font-weight: 600;
    }

    .error {
      position: absolute;
      display: flex;
      align-items: center;
      color: $error-red;
      bottom: 0;
      .error-text {
        color: red;
      }
    }
  }

  .capacity {
    grid-area: capacity;
    margin-top: 10px;
    font-size: 12px;
    padding-left: 20px;

    .title {
      font-weight: 600;
      margin-bottom: 8px;
    }

    .data {
      display: flex;
      justify-content: space-between;
      .chart {
        display: inline-block;
      }
      .details-layout {
        display: inline-block;
        vertical-align: top;
        font-weight: bold;
        .quantity {
          display: flex;
          align-items: center;
        }
        .lighter {
          color: $light-grey;
        }
      }
    }
  }
}

