@import "./../../../Variables.scss";

.header-widgets{
  position: absolute;
  top: 0;
  right: 0;

  .login-signup-container {
    float: right;

    .divider {
      display: inline-block;
      width: 1px;
      background-color: #fff;
      height: 7px;
      float: right;
      margin-top: 14px;
    }

    a.login-signup, button.login-signup {
      height: 100%;
      font-size: 11px;
      font-weight: 600;
      color: #fff;
      margin: 0 20px;
      letter-spacing: 0.5px;
      background-color: transparent;
      float: right;
      border: none;
      line-height: $header-height;
      padding: 0;

      &:hover {
        color: #E2E2E2;
      }
    }
  }

  .my-services-button{
    height: $header-height;
    width: 114px;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    border: none;
    outline: none;
    display: inline-block;
    float: right;
    text-align: center;
    vertical-align: middle;
    line-height: $header-height;

    &:hover {
      color: #E2E2E2;
    }
  }

  .right-side-menu {  
    //------------------- user menu ---------------------------
    .user-menu-header {
      height: 70px;
      width: 100%;
      padding: 18px 40px;
      color: $text-dark-grey;
      font-size: 15px;
      box-shadow: 2px 2px 6px 0 rgba(187, 187, 187, 0.5);
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 1;

      .info {
        display: flex;
        align-items: center;
      }

      .title {
        font-size: 17px;
        color: #00266b; // Hila?
        margin-left: 14px;
      }

      .primary-fill {
        width: 23px;
        height: 23px;
        fill: $primary-blue !important;
      }

      .logout-button {
        width: 91px;
        padding: 0;
      }
    }

    .user-title{
      font-size: 13px;
      padding: 5px 0 0 0;
    }
    .user-title-password{
      font-size: 13px;
      padding: 35px 0 0 0;
    }

    .user-text{
      font-size: 14px;
      padding: 15px 50px 0 0;
      font-weight: 600;
    }

    .user-info-row-outer {
      position: relative;
      width: 100%;
      height: 80px;
      padding: 0 50px 0 50px;

      .user-info-row-inner{
        position: relative;
        width: 100%;
        height: 100%;
        border-top: 1px solid transparent;
        border-bottom: 1px solid #f0f0f0;
        color: $text-dark-grey;

        .edit-user-info-button {
          position: absolute;
          right: 5px;
          top: 30px;
          width: 25px;
          height: 25px;
          background-color: transparent;

          svg.edit{
            background-color: transparent;
            fill: $light-grey
          }
        }
        &:hover{
          border-top-color: $primary-blue;
          border-bottom-color: $primary-blue;
          color: $primary-blue;

          .edit-user-info-button{
            svg.edit{
              fill: $primary-blue;
            }
          }
        }
      }

      .user-info-row-inner-static{
        position: relative;
        width: 100%;
        height: 100%;
        border-top: 1px solid transparent;
        border-bottom: 1px solid #f0f0f0;
        color: $text-dark-grey;
      }
    }


    .user-edit-row-outer{
      position: relative;
      width: 100%;
      height: 208px;
      padding: 0 50px 0 50px;

      .user-edit-row-inner{
        position: relative;
        padding-top: 30px;
        border-top: 1px solid $primary-blue;
        border-bottom: 1px solid $primary-blue;

        p{
          margin: 10px 0 30px 0;
          font-size: 13px;
        }
      }

      input{
        width: 370px;
        height: 35px;
        padding-left: 10px;
        margin: 10px 0 40px 0;
        border: 1px solid $light-grey;
      }

      .name-length-error{
        margin-top: -18px;
        float:right;
        color: $text-error;
        font-size: 12px;
      }

      button{
        margin-bottom: 35px;
        &.submit-button{
          float: left;
          margin-right: 50px;
        }
      }
    }
    .corporate-password-tooltip{
      font-size: 12px;
      max-width: 400px;
    }

  }

  .edit-user-button {
    position: relative;
    height: $header-height;
    width: 140px;
    float: right;
    background-color: transparent;
    color: #fff;
    text-decoration: none;
    text-align: center;
    font-size: 13px;
    line-height: $header-height;
    padding: 0;
    font-weight: 600;

    &.active, &:hover {
      color: #E2E2E2;
      transition: all 0.3s ease-out;
      text-decoration: none;

      .lefty, .righty{
        background-color: #E2E2E2;
      }
    }

    &.active {
      .lefty{
        transform: rotate(-40deg);
        background-color: #E2E2E2;
      }

      .righty{
        transform: rotate(40deg);
        background-color: #E2E2E2;
      }
    }

    .lefty{
      top: 0;
      left: -3px;
      transform: rotate(40deg);
      transform-origin: center;

    }

    .righty{
      top: 0;
      transform: rotate(-40deg);
      transform-origin: center;
    }

    .lefty, .righty{
      width: 5px;
      height: 2px;
      position: absolute;
      background-color: #fff;
      transition: transform 0.4s cubic-bezier(.25,.8,.25,1);
    }

    .arrow{
      position: absolute;
      right: 27px;
      bottom: 18px;
      display: inline-block;
    }
  }

}
