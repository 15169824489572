@import "@netapp/bxp-design-system-react/styles/colors";

.popover {
  display: flex;
  align-items: center;
  font-size: 12px;
  span {
    margin-right: 4px;
    color: $error-red;
  }

  svg {
    [class*="primary-fill"] {
      fill: $error-red;
    }
    [class*="primary-stroke"] {
      stroke: $error-red;
    }
  }
}
