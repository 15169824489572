@import "@netapp/bxp-design-system-react/styles/colors";
@import "../../../../styles/constants.module";

:global(body) {
  .content {
    width: 500px;
  }

  .notification {
    margin-top: 30px;
    background-color: #fff;
  }

  .form-field {
    margin-bottom: 0;
  }

  .title {
    margin-bottom: 25px;
  }

  .text-title {
    color: $primary-blue;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  ol {
    color: $text-grey;
    font-size: 14px;
    font-weight: normal;
    padding-left: 14px;
    margin: 0 0 25px 0;

    li {
      margin-bottom: 10px;
    }

    div {
      line-height: 2.29;
    }

    .inline-bold {
      font-weight: 600;
    }
  }

  .copy-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 3px 6px 2px 0;
    background-color: $white-grey;
    margin: 10px 0;
    position: relative;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
      background-color: transparent;
    }

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $text-grey;
      font-weight: 600;
      margin-left: 10px;
      margin-right: 4px;
    }

    .copy-icon {
      width: 17px;
      height: 17px;
      :global .primary-fill {
        fill: $text-grey;
      }
    }

    .popover {
      opacity: 0;
      position: absolute;
      right: 0;
    }
  }

  .widget {
    margin-bottom: 25px;
  }
}

