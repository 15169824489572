@import "@netapp/bxp-design-system-react/styles/colors";
@import "../../../../styles/constants.module";

.content {
  display: flex;
  flex-direction: column;
  width: $page-content-width-large;
  margin: 0 auto;
}

.ad-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
          "title     empty1"
          "domain    organisational-unit"
          "dns       bios"
          "username  .";
  row-gap: 0;
  column-gap: 63px;
  margin-top: 30px;
  font-size: 14px;
  color: $text-grey;
  font-weight: normal;
}

.ad-grid-item {
  display: flex;
  border-bottom: 1px solid $disabled-grey;
  padding: 16px 0;
  font-size: 14px;
}

.info-label {
  width: 50%;
  color: $text-dark-grey;
  white-space: nowrap;
}

.data {
  width: 50%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 40px;
}
