@import "@netapp/bxp-design-system-react/styles/colors";
@import "../../styles/constants.module.scss";

:global(body) {
  .container {
    width: 760px;
  }

  .existing-account {
    margin: 36px auto 0 auto;
  }

  .new-account-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
            "name         rgButtons"
            "subscription rgButtons"
            "location     .         ";
    column-gap: 40px;
    row-gap: 35px;
    margin-top: 36px;
  }

  .resource-group-radio {
    grid-area: rgButtons;
    color: $text-grey;

    .title-and-popover {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .radio-buttons {
      padding-top: 12px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-top: solid 1px $input-field-border;
      margin-top: 6px;
      margin-bottom: 27px;

      > label:not(:first-child) {
        margin-left: 32px;
      }
    }
  }

  .create-resource-group-field {
    position: relative;

    .create-resource-group-field-button {
      position: absolute;
      right: 0;
      top: 27px;
      height: 40px;
      align-self: flex-end;
      border-radius: 4px;
    }
  }

  .accountName {
    grid-area: name;
  }

  .subscription {
    grid-area: subscription;
  }

  .location {
    grid-area: location;
  }

  .create-rg-notification {
    position: absolute;
    bottom: -24px;
    right: 0;
    left: 0;

    span {
      color: $text-grey;
    }
  }
}




