@import "@netapp/bxp-design-system-react/styles/colors";
@import "../styles/constants.module";

.page-grid-layout, .page-grid-layout-no-footer {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: $cvs-header-height auto $cvs-footer-height;
  grid-template-areas:
          "head"
          "cont"
          "foot";
  height: 100vh;
}

.page-grid-layout-no-footer {
  grid-template-rows: $cvs-header-height auto;
  grid-template-areas:
          "head"
          "cont";
}

.page-grid-header {
  grid-area: head;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  box-shadow: 4px 4px 15px 0 rgba(187, 187, 187, 0.5);
  background: white;
  z-index: 3;

  .close-button {
    position: absolute;
    right: 36px;
    background-color: transparent;
    svg {
      width: 20px;
      height: 20px;
      [class*="primary-fill"] {
        fill: $divider-grey;
      }
    }
  }
}

.page-grid-content {
  grid-area: cont;
}

.page-grid-footer {
  grid-area: foot;
  position: sticky;
  bottom: 0;
  background: white;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: $header-content-width-large;
  margin: 0 auto;
}

.header-info {
  display: flex;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
  }

  .title {
    color: $primary-blue;
    margin-left: 19px;
  }
}
