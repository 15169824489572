@import "src/constants";
@import "./../../Variables.scss";

.main-container {
  max-width: 1135px;
  width: calc(100% - 100px);
  margin: 0 auto;;

  padding-top: $new-secondary-header-height;

  @media(max-width: $break-medium) {
    padding-top: $new-secondary-header-height-medium;
  }

  @media(max-width: $break-small) {
    padding-top: $new-secondary-header-height-small;
  }

  .buttons-container {
    display: flex;
    align-items: center;
    margin-top: 40px;
  }

  .service-title {
    .service-icon {
      width: 30px;
      height: 30px;
      margin-right: 15px;
      position: relative;
      top: 8px;
    }
  }

  .reset-error, .reset-success {
    margin-top: 15px;
    font-size: 13px;
    min-height: 18px;
  }

  .reset-error {
    color: $text-error;

  }

  .reset-success{
    color: $primary-blue;
  }

}

.loader {
  composes: inline-loader;
  margin-left: 50px;
}
