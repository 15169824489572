@import "@netapp/bxp-design-system-react/styles/colors";

:global(body) {
  .content {
    width: 500px;
  }

  .info {
    margin-top: 14px;
    line-height: 1.64;
    color: $text-grey;
  }

  .form-field {
    margin-top: 33px;
    margin-bottom: 39px;
  }

  .button-container {
    margin-top: 0;
  }
}

