@import "./../../Variables.scss";

.base {
  position: absolute;
  line-height: 180px;
  z-index: 1;

  a {
    line-height: 1.5;
    font-size: 15px;
    color: #fff;
    font-weight: 600;
  }

  svg {
    fill: #fff;
    margin-right: 10px;
    position: relative;
    top: 3px;
  }
}