@import "./../../Variables.scss";


.base {
  min-height: calc(100% - #{$header-height});
  background-color: $grey-background;
  position: relative;
  padding-top: 40px;

  h3{
    font-size: 30px;
    font-weight: 600;
    line-height: 22px;
    color: $text-dark-grey;
    margin: 0 0 61px;
  }
}

.doc-card {
  box-shadow: 0 5px 4.8px 0.3px rgba(0,0,0,.05);
  width: 100%;
  background-color: #fff;
  margin-bottom: 10px;
  position: relative;
  padding: 25px 235px 26px 30px;

  @media(max-width: 1366px) {
    height: 155px;
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
    color: $primary-blue;
    line-height: 1.1;
    margin: 0 0 13px;
  }

  p {
    font-size: 13px;
    line-height: 1.69;
    color: $grey-6;
  }

  a, span{
    position: absolute;
    top: 31px;
    right: 30px;
    height: 40px;
    width: 185px;
    font-size: 12px;
    line-height: 40px;
    text-align: center;
    font-weight: 600;
    border-radius: 5px;
    display: inline-block;
  }

  a {
    color: #3c84ff;
    border: 1px solid #3c84ff;
    transition: all 0.3s;

    &:hover{
      background-color:#3c84ff;
      color: #fff;
    }
  }

  span {
    color: #999999;
    border: 1px solid #999999;
  }
}
