@import "@netapp/bxp-design-system-react/styles/colors";
@import "../../styles/constants.module";

.info-item-container {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  color: $text-grey;

  * {
    flex: 0 1 auto;
    line-height: 1.64;
    font-size: 14px;
  }

  a, button {
    margin: 0 4px;
  }

  svg {
    flex: 0 0 auto;
    width: 8px;
    height: 13px;
    margin-right: 10px;
    margin-top: 6px;
    :global .primary-fill {
      fill: $primary-blue;
    }
  }

  .transparent {
    :global .primary-fill {
      fill: white;
    }
  }
}
