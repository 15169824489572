@use "@netapp/bxp-design-system-react/styles/colors";

.card{
  margin: 80px auto;
  width: 790px;
  height: 530px;
}

.card-header{
  color: colors.$primary-blue;
  font-size: 20px;
  font-weight: 300;
}

.content-header{
  color: colors.$dark-blue;
  margin-bottom: 30px;
}

.register-button{
  color: colors.$dark-blue;
  margin-bottom: 30px;
}

.cm-link{
  margin-bottom: 30px;
  a{
    color: colors.$dark-blue;
    font-weight: 600;
  }
}