@import "@netapp/bxp-design-system-react/styles/colors";
@import "../../styles/constants.module";

:global(body) {
  .container {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0 0 0 50px;
    border-right: 1px solid $divider-grey;
  }

  .title {
    margin-bottom: 45px;
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 370px;

    label span {
      font-size: 14px;
    }
  }

  .gcp-json {
    position: relative;

    button {
      position: absolute;
      bottom: 35px;
      right: 10px;
      width: 100px;
      height: 38px;
      &:hover {
        background-color: $mid-blue;
      }
      &:disabled {
        cursor: not-allowed;
      }
    }

    textarea {
      height: 150px;
    }
  }

  .field {
    margin-bottom: 35px;
  }
}

