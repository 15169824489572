@import "@netapp/bxp-design-system-react/styles/colors";

:global(body) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info {
    margin-bottom: 30px;
  }

  .volumes {
    margin-bottom: 35px;
  }

  .snapshot-warn {
    width: 360px;
    margin-top: 8px;
    span {
      color: $text-grey;
    }
  }
}

