@import "@netapp/bxp-design-system-react/styles/colors";

.modal-header {
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
}

.redirect {
  margin-left: 4px;
  color: $primary-blue;
}
