:global(body) {
  .connectivity-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
            "primary-ip   ad-domain"
            "secondary-ip ad-username-and-password"
            "bios         organisational-unit";
    row-gap: 35px;
    margin-top: 38px;
  }

  .alignment {
    justify-self: end;
  }
}

