@import "@netapp/bxp-design-system-react/styles/colors";

.container {
  display: flex;
  align-items: center;
  color: $primary-blue;
  padding: 0;
  margin: 0;
  background-color: transparent;

  &.disabled {
    cursor: not-allowed;
  }

  .index {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: solid 1.5px $primary-blue;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    margin-right: 9px;

    &.selected {
      color: $white;
      border-color: $accent-blue;
      background-color: $accent-blue;
    }
  }

  .done-icon {
    width: 24px;
    height: 24px;
    margin-right: 9px;
    [class*="primary-fill"] {
      fill: $primary-blue;
    }
    [class*="primary-stroke"] {
      stroke: $primary-blue;
    }

    &.selected {
      [class*="primary-fill"] {
        fill: $accent-blue;
      }
      [class*="primary-stroke"] {
        stroke: $accent-blue;
      }
    }
  }

  .title {
    color: $primary-blue;

    &.selected {
      color: $accent-blue;
    }
  }
}
