.cc-service-icon-storage {
  --icon-primary: #5933b4;
}

.cc-service-icon-services {
  --icon-primary: #1ca6ff;
}

.cc-service-icon-control {
  --icon-primary: #cc3cd6;
}

.cc-service-icon-analytics {
  --icon-primary: #3CB09F;
}
