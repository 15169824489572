@import "src/constants";

.outer-container {
  text-align: center;
  padding: 80px;

  .button-container {
    display: flex;
    justify-content: center;
  }

  svg {
    width: 50px;
    height: 50px;
    margin-bottom: 46px;
  }

  p:first-of-type {
    color: $lochmara-blue;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p:nth-of-type(2) {
    font-size: 17px;
    line-height: 2;
    width: 575px;
    margin: 0 auto 37px auto;
  }

  button, a {
    width: 198px;
  }
}
