@import "@netapp/bxp-design-system-react/styles/colors";

.question {
  margin-bottom: 20px;
}

.note {
  font-weight: 600;
}

