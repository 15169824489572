@import "@netapp/bxp-design-system-react/styles/colors";
@import "../../styles/constants.module.scss";

:global(body) {
  .container {
    width: 760px;
  }

  .existing-pool {
    margin: 36px auto 0 auto;
  }

  .new-capacity-pool-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
            "nameAndSize"
            "level";
    row-gap: 35px;
    width: 360px;
    margin: 36px auto 0 auto;
    .name-and-size {
      display: flex;
    }
  }

  .pool-name {
    width: 266px;
    margin-right: 10px;
  }

  .pool-size {
    width: 84px;
  }
}


