@import "@netapp/bxp-design-system-react/styles/colors";
@import "../../../styles/constants.module";

.action-button-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  border-top: 1px solid $disabled-grey;
  margin-top: 49px;

  button {
    width: 140px;
  }
}
