@import "@netapp/bxp-design-system-react/styles/colors";
@import "../../styles/constants.module.scss";

:global(body) {
  .container {
    width: 850px;
  }

  .two-col-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
            "left-col  right-col";
    margin-top: 38px;
  }

  .left-col, .right-col {
    display: flex;
    flex-direction: column;
    color: $text-grey;
    font-size: 14px;

    .title {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 40px;
    }
  }

  .right-col {
    width: 409px;
  }

  .left-col {
    width: 360px;
  }

  .error-area {
    position: fixed;
    width: 850px;
    left: 50%;
    transform: translateX(-50%);
    bottom: $cvs-footer-height + 42px;
  }

  .policy-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
    border-bottom: solid 1px $input-field-border;
    position: relative;
  }

  .dynamic-policies-form {
    > div {
      border-top: solid 1px $menu-divider;
    }

    & > div:first-child {
      border-top: solid 1px transparent;
    }
  }

  .nfs-version, .dual-version, .smb-encryption {
    margin-top: 35px;

    .version-title {
      padding-bottom: 8px;
      border-bottom: solid 1px $input-field-border;
      margin-bottom: 14px;

      &.disabled {
        color: $input-field-border;
        border-bottom: solid 1px $disabled-grey;
      }
    }

    .error {
      font-size: 13px;
      font-weight: 600;
      margin-top: 5px;
      color: $error-red;
    }

    .checkboxes {
      display: flex;

      > label:first-child {
        margin-right: 38px;
      }
    }
  }

  .smb-encryption {
    margin-top: 16px;
  }

  .widget {
    > div:first-child {
      margin-right: 40px;
    }
  }

  .smb-second-step-container {
    display: flex;
    flex-direction: column;
    margin-top: 38px;
    color: $text-grey;
    font-size: 14px;

    .edit-ad {
      display: flex;
      justify-content: space-between;
      margin-top: 28px;
      border-bottom: 1px solid $disabled-grey;
      padding-bottom: 6px;
    }

    .back {
      display: flex;
      align-items: center;
      button {
        padding: 0;
        margin: 0;
        background: transparent;
      }
      svg {
        margin-right: 20px;
        width: 25px;
        height: 25px;
        :global {
          .primary-fill {
            fill: $text-grey;
          }
        }
      }
    }

    .connectivity-form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
              "primary-ip   ad-domain"
              "secondary-ip ad-username-and-password"
              "bios         organisational-unit";
      row-gap: 35px;
      column-gap: 128px;
      margin-top: 38px;

      .name-and-pass {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .disabled-field {
    label, input {
      color: $input-field-border;
    }
  }

  // export policy
  .policy-grid, .policy-grid-no-versions {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-template-rows: min-content;
    grid-template-areas:
            "ip      rw-buttons"
            "version v-buttons";
    column-gap: 22px;
    row-gap: 29px;
    width: 409px;
    position: relative;
    align-items: center;
    padding: 30px 0;

    button {
      padding: 0;
      margin: 0;
      position: absolute;
      right: -40px;
      top: 50%;
      background-color: transparent;

      svg {
        width: 20px;
        height: 20px;
        :global .primary-fill {
          fill: $disabled-grey;
        }
      }
    }

    .ip {
      margin-bottom: 0;
      > label > div {
        margin: 0;
      }
    }

    .radio-buttons, .checkbox-buttons {
      display: flex;
      align-items: center;

      > label:first-child {
        margin-right: 30px;
      }
    }

    .radio-buttons {
      justify-content: space-between;
      > label:first-child {
        margin-right: 0;
      }
    }

    .checkbox-buttons {
      position: relative;
      > label:first-child {
        margin-right: 40px;
      }
    }

    .version-error {
      position: relative;
      .error {
        position: absolute;
        left: 0;
        bottom: -22px;
        white-space: nowrap;
        color: $error-red;
        font-size: 13px;
        font-weight: 600;
      }
    }
  }

  .policy-grid-no-versions {
    grid-template-rows: 1fr;
    grid-template-areas:
            "ip      rw-buttons";
    row-gap: 0;
  }

  .sole-filed {
    margin: 39px auto 0 auto;
  }

  .disabled {
    color: #c8c8c8;
    cursor: not-allowed;
  }
}


