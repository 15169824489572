@import "src/constants";

@mixin main-content($maxWidth: 1135px, $horizontalMargin: 100px) {
  max-width: $maxWidth;
  margin: 40px auto 0 auto;
  width: calc(100% - #{$horizontalMargin});
}

.main-content-container {
  @include main-content();
}
