@import "src/constants";

@mixin base-ul($listTypeStyle: disc, $lineHeight: 2em, $paddingInlineStart: 0px, $listItemSpacing: 8px, $bulletImageUrl: null, $pseudoImageElementLeftMargin: null, $pseudoImageElementRightMargin: null, $chevronColor: $lochmara-blue) {
  & {
    margin: 0;
    list-style-type: $listTypeStyle;
    padding-inline-start: $paddingInlineStart;

    > li {
      margin-block-end: $listItemSpacing;
      margin-inline-start: 1.2em;
      line-height: $lineHeight;
    }

    @if ($bulletImageUrl != null) {
      // To change bullet image color or size - change width/height/background-color of pseudo element
      >li::before {
        width: 7px;
        height: 13px;
        content: '';
        mask: url($bulletImageUrl);
        margin-inline-start: $pseudoImageElementLeftMargin;
        margin-inline-end: $pseudoImageElementRightMargin;
        mask-size: cover;
        display: inline-block;
        background-color: $lochmara-blue;
      }
    }
  }
}

.disc-ul {
  @include base-ul();
}

.circle-ul {
  @include base-ul($listTypeStyle: circle);
}

.chevron-ul {
  @include base-ul($listTypeStyle: none, $paddingInlineStart: 13px, $bulletImageUrl: "./bullet.svg", $pseudoImageElementLeftMargin: -30px, $pseudoImageElementRightMargin: 20px);
}

.number-ol {
  @include base-ul($listTypeStyle: decimal);
}
