@import "./../../Variables.scss";
@import "../../styles/app/layout.module";

.federation-setup {
  .main-content-container {
    @include main-content($maxWidth: 1110px, $horizontalMargin: 100px);
    width: 1110px;
    @media(max-width: 1566px) {
      width: 850px;
    }

    @media(max-width: 1366px) {
      width: 760px;
    }
  }

  .email-not-verified {
    text-align: center;
    padding-bottom: 40px;
    margin-top: 28px;
    margin-bottom: 32px;

    > svg {
      margin-top: 25px;
    }


    button {
      width: auto;
      margin: 24px auto 0 auto;
    }

    .success-message {
      color: $success;
      margin-top: 30px;

      svg {
        vertical-align: bottom;
        margin-right: 15px;

        * {
          fill: $success
        }
      }
    }

  }

  .started-by-other-user {
    text-align: center;
    margin-bottom: 50px;

    > svg {
      margin-top: 25px;
    }

    p {
      color: $grey-6;
      margin-top: 50px;
      font-size: 20px;
    }

    .creator-user-email {
      font-weight: 600;
      color: $lochmara-blue;
    }

    .contact-support {
      font-size: 17px;
    }
  }

  .connection-already-enabled {
    text-align: center;
    margin-bottom: 50px;

    h3 {
      font-size: 20px;
      font-weight: 600;
      color: $lochmara-blue;
      margin-top: 50px;
    }

    p {
      color: $grey-6;
      margin-top: 25px;
    }
  }

  .contact-support-button {
    margin: 0;
    padding: 0;
    font-size: inherit;
    color: $primary-blue;
    background: none;
  }

  .create-connection-component {
    h2 {
      font-size: 20px;
      font-weight: 600;
      color: $lochmara-blue;
    }

    p {
      margin-top: 15px;
    }

    h3 {
      font-size: 16px;
      font-weight: 600;
      color: $lochmara-blue;
      margin-top: 50px;
    }

    .protocol-buttons-box {
      margin-top: 40px;
      display: flex;

      .protocol-button {
        @extend %standard-card;
        flex-grow: 1;
        flex-basis: 0;
        height: 152px;
        color: $grey-6;
        font-size: 17px;
        font-weight: 600;
        border-bottom: 5px solid $white-background;

        &:not(:first-child) {
          margin-left: 20px;
        }

        &:disabled {
          color: lighten($grey-6, 18%);
          cursor: initial;
        }

        &.active {
          color: $primary-blue;
          border-bottom: 5px solid $primary-blue;
        }
      }
    }

  }

  .create-connection-instructions-and-form {
    margin-top: 60px;
    border-radius: 4px;
    background-color: $white-background;
    box-shadow: 2px 2px 5px 0 rgba(187, 187, 187, 0.5);
    font-size: 15px;

    .section-header {
      line-height: 65px;
      padding-left: 75px;
      font-size: 20px;
      color: $primary-blue;
      box-shadow: 2px 2px 5px 0 rgba(187, 187, 187, 0.5);

      height: 65px;
    }

    .instructions {
      padding: 0 75px 50px 75px;

      h4 {
        color: $lochmara-blue;
        font-weight: 600;
        text-decoration: underline;
      }

      ol li {
        padding-left: 9px;
      }

      code {
        font-size: 13px;
        display: block;
        overflow-x: auto;
        background-color: #2f383d;
        padding: 15px;
        color: white;
        font-family: Roboto Mono, Menlo, Monaco, Consolas, Courier New, monospace;

        &.language-powershell {
          span.built_in {
            color: #ff9a56;
          }

          span.string {
            color: #00fbca;
          }
        }

        &.language-xml {
          span.attribute-name {
            color: mediumpurple;
          }

          span.attribute-value {
            color: #ff9a56;
          }

          span.text {
            color: #00fbca;
          }
        }
      }

      span.code {
        border-radius: 4px;
        font-size: 90%;
        padding: 3px 8px;
        color: #5c666f;
        background: #eef0f1;
        font-family: Roboto Mono, Menlo, Monaco, Consolas, Courier New, monospace;
      }
    }

    .form {
      padding: 50px 75px 70px;
      background-color: $link-water-blue;

      form {
        width: 440px;

        .submit-button {
          width: 100%;
        }
      }

      .test-button {
        width: 500px;
        margin-top: 18px;
      }

      .download-metadata{
        width: 400px;
        margin-top: 18px;
        margin-bottom: 40px;
      }

      .copy-to-clipboard-field {
        display: flex;
        .copy-button {
          background: rgb(246, 246, 246);
          position: relative;
          height: 80%;
          margin-top: 7px;
        }
      }
      .warning-note{
        font-size: 13px;
      }

      .change-my-connection-settings-button {
        width: 246px;
        background: none;
        margin: 20px 0;
        padding: 0;
        font-size: 15px;
        font-weight: 600;
        color: $primary-blue;
      }

      .error-message {
        color: $text-error;
        font-size: 13px;
        font-weight: 600;
        margin-top: 20px;
      }
    }
  }

  .federation-setup-test-success {
    height: 100vh;
    background-color: gray;
  }

  .main-view-error-message-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
    line-height: 2.29;
    button{
      height: 42px;
    }
  }

  .main-view-error-message{
    padding: 5px 57px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 600;
    width: max-content;
    background-color: #FF9B9B;
    color: white;
  }
}

.federation-setup-test-success {
  height: 100vh;
  background-color: $grey-background;

  .main-content-container {
    margin-top: 0;
    text-align: center;

    svg {
      margin-top: 140px;
    }

    h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.9;
      color: $lochmara-blue;
    }

    h4 {
      font-weight: normal;
      font-size: 20px;
      color: $grey-6;
    }
  }
}

.support-email-container {
  margin: 60px auto;

  .support-email-field {
    width: 400px;
    height: 40px;
    border-radius: 4px;

    form{
      width: 100%;
      display: flex;
    }

    button {
      height: 40px;
    }
  }
}

.sub-label {
  font-size: 14px;
  color: $lochmara-blue;
  margin-top: -22px;
  margin-bottom: 30px;
}

.or-divider {
  display: flex;
  margin-bottom: 36px;

  span {
    padding: 0 20px;
  }

  &:before, &:after {
    height: 10px;
    content: " ";
    flex-grow: 1;
    border-bottom: 1px solid $grey-6;
  }
}

.upload-button-wrapper {
  margin: 28px 0 32px 0;
  position: relative;
  overflow: hidden;
  display: inline-block;

  &:hover {
    button {
      color: $primary-blue-hover;
      border-color: $primary-blue-hover;
      cursor: pointer;
    }
  }

  input[type=file] {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }

  input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer;
  }
}

.error-message {
  color: $error;
  margin-top: 30px;
  font-weight: 600;

  a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
}

.button-pair {
  display: flex;

  button {
    flex-grow: 1;
  }
;
}

.link{
  display: block;
  font-size: 14px;
  color: $primary-blue;
  margin-top: 8px;
  font-weight: bold;
  svg {
    position: relative;
    top: 4px;
    left: 8px;
  }
}
