@import "@netapp/bxp-design-system-react/styles/colors";

.setup-container {
  width: 800px;
}

.setup-details-container {
  width: 610px;
  color: $text-grey;

  .main-text {
    line-height: 2.14;
    font-weight: normal;
  }

  .bullets {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
}

.form {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
}

.bullet-item-container {
  display: flex;
  position: relative;
  padding-left: 20px;
  color: $text-grey;
  line-height: 1.64;
  margin-bottom: 20px;

  .chevron {
    position: absolute;
    left: 0;
    top: 6px;
    width: 8px;
    height: 13px;
    :global .primary-fill {
      fill: $accent-blue;
    }
  }
}
