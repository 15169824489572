@import "@netapp/bxp-design-system-react/styles/colors";
@import "../../styles/constants.module";

:global(body) {
  .container {
    width: 745px;
    color: $text-grey;
    font-size: 14px;
  }

  $bottom-padding: 20px;
  .snapshot-form {
    width: 100%;
    height: 100px;
    transition: height 0.4s cubic-bezier(.25, .8, .25, 1);
    box-shadow: 2px 2px 6px 0 #c8c8c8;
    background-color: #fff;
    padding: 27px 30px $bottom-padding 30px;
    position: relative;
    overflow: hidden;
  }

  .show-directory-checkbox {
    margin-bottom: $bottom-padding;
  }

  .snapshot-form-enabled {
    height: 590px;
    overflow: visible;
  }

  .snapshot-details-row {
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(.25, .8, .25, 1) 0.1s;
  }

  .snapshot-details-row-enabled {
    opacity: 1;
  }

  .policies-title {
    font-weight: 600;
    border-bottom: solid 1px $disabled-grey;
    padding-bottom: 9px;
    margin-bottom: 26px;
  }

  .policies {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 25px;
    row-gap: 35px;
  }

  .policy {
    display: flex;
  }

  .policy-checkbox-label {
    font-weight: 600;
  }

  .snapshot-toggle-row {
    position: relative;
  }

  .snapshot-toggle-title {
    font-size: 16px;
    color: #2359a3;
    margin-bottom: 12px;
    line-height: 22px;
  }

  .snapshot-toggle-description {
    font-size: 13px;
    color: $text-grey;
    line-height: 1.54;
    max-width: 550px;
    margin-bottom: 33px;
    height: 40px;
  }

  .snapshot-toggle {
    position: absolute;
    top: 0;
    right: 0;
  }

  .error-area {
    position: fixed;
    width: 819px;
    left: 50%;
    transform: translateX(-50%);
    bottom: $cvs-footer-height + 42px;
  }

  .snapshot-field {
    width: 170px;
    margin-bottom: 0;

    &.with-margin {
      margin-left: 20px;
    }

    &.shorter-filed {
      width: 70px;
    }

    &.wider-field {
      width: 200px;
    }
  }
}

