@import "./../../Variables.scss";
@import "src/constants";

.dialog {
  :global {
    @include standard-modal($height: null, $width: null);
    .modal {
      max-width: 700px;
    }
  }
}

.content-success {
  span {
    word-break: break-word;
  }
}

.content-rename-finished {
  border-bottom: solid 1px #e4e4e4;
  padding:40px
}

.content-error {
  composes: content-success;
  height: calc(100% - 140px);
}


.banner-success {
  width: 100%;
  background-color: #F2FCF9;
  padding: 28px 105px 28px 125px;
  position: relative;
  font-size: 14px;
  line-height: 1.75;
  color: #404040;

  svg {
    position: absolute;
    top: 40px;
    left: 60px;
    width: 35px;
    height: 29px;
  }
}

.banner-error {
  width: 100%;
  background-color: rgba(202, 54, 82, 0.15);
  padding: 40px 111px 40px 111px;
  position: relative;

  font-size: 14px;
  line-height: 1.75;
  color: #404040;

  span {
    color: #2c5e9c;
  }

  svg {
    position: absolute;
    top: 40px;
    left: 60px;
    width: 31px;
    height: 31px;

    :global .primary-fill {
      fill: #CA3652
    }
  }
}

.content {
  padding: 29px 60px 0 60px;
  font-size: 14px;
  color: #404040;
  position: relative;


  &::-webkit-scrollbar {
    width: 7.5px;
  }
;

  &::-webkit-scrollbar-track {
    background-color: #eee;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
}

.footer {
  margin-top: 30px;
  padding: 0 60px 41px;
  position: relative;
  button, a {
    width: initial;
  }
}

.footer-info {
  border-top: solid 1px #e4e4e4;
  font-size: 13px;
  color: #6c6f78;
  margin-top: 20px;
  padding-top: 10px;

  span {
    color: #434957;
  }
}

.subscription-popup-container{
  position: absolute;
  right: 60px;
  top: 29px;
}

.subscription-popup{
  width: 310px;
  transform: translate(-50%, 10px);

  :global {
    .arrow {
      top: -9px;
      left: calc(50% - 10px);
      border-left: 10px solid transparent !important;
      border-right: 10px solid transparent !important;
      border-bottom: 10px solid #fff !important;
      bottom: initial !important;
    }
    .content {
      font-size: 13px;
      color: #434957;
      line-height: 1.77;
      padding: 13px 20px 18px;
      border-radius: 4px;
      box-shadow: 2px 2px 40px 2px rgba(0, 0, 0, 0.17);
      background-color: #fff;
      border: solid 1px #e2e2e2;
    }
  }
}

.account-popup-container{
  margin-left: 510px;
  position: relative;
}

.account-popup{
  width: 310px;
  transform: translate(-50%, 10px);

  :global {
    .arrow {
      top: -9px;
      left: calc(50% - 10px);
      border-left: 10px solid transparent !important;
      border-right: 10px solid transparent !important;
      border-bottom: 10px solid #fff !important;
      bottom: initial !important;
    }
    .content {
      font-size: 13px;
      color: #434957;
      line-height: 1.77;
      padding: 13px 20px 18px;
      border-radius: 4px;
      box-shadow: 2px 2px 40px 2px rgba(0, 0, 0, 0.17);
      background-color: #fff;
      border: solid 1px #e2e2e2;
    }
  }
}



.account-checkbox {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #404040;
  position: relative;
  box-shadow: 2px 2px 6px #e0e0e0;
  border: 0.5px solid #e0e0e0;
  height: 56px;
  padding: 16px;

  &:hover {
    background-color: var(--hover-bg);
  }

  input {
    margin-right: 12px;
    position: relative;
    top: 2px;
  }
}

.error {
  color: $text-error;
  font-size: 13px;
  min-height: 18px;
  font-weight: 600;
  text-align: right;
  position: absolute;
  top: 11px;
}

