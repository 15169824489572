@import "@netapp/bxp-design-system-react/styles/colors";
@import "../../../../styles/constants.module";

.indicator {
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-right: 6px;

  &.creating {
    background-color: $warning-orange;
  }

  &.available {
    background-color: $success-green;
  }

  &.updating {
    background-color: $warning-orange;
  }

  &.disabled {
    background-color: $light-grey;
  }

  &.deleting {
    background-color: $warning-orange;
  }

  &.deleted {
    background-color: $light-grey;
  }

  &.error {
    background-color: $light-grey;
  }

  &.note {
    background-color: $primary-blue;
  }
}
