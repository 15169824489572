@import "src/system-constants";

.container {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: $text-grey;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .type-icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  &.success {
    color: $success-green;
    .type-icon {
      :global .primary-fill {
        fill: $success-green;
      }
      :global .primary-stroke {
        stroke: $success-green;
      }
    }
  }

  &.error {
    color: $error-red;
    .type-icon {
      :global .primary-fill {
        fill: $error-red;
      }
      :global .primary-stroke {
        stroke: $error-red;
      }
    }
  }

  &.warning {
    color: $warning-orange;
    .type-icon {
      :global .primary-fill {
        fill: $warning-orange;
      }
      :global .primary-stroke {
        stroke: $warning-orange;
      }
    }
  }

  &.information {
    color: $information-blue;
    .type-icon {
      :global .primary-fill {
        fill: $information-blue;
      }
      :global .primary-stroke {
        stroke: $information-blue;
      }
    }
  }
}
