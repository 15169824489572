@import "src/constants";

@mixin standard-button($backgroundColor: white,
                       $backgroundHoverColor: white,
                       $borderColor: none,
                       $borderHoverColor: none,
                       $textColor: white,
                       $textHoverColor: white,
                       $width: 136px,
                       $fontSize: 15px) {
  width: $width;
  height: 40px;
  background-color: $backgroundColor;
  border-radius: 2px;
  color: $textColor;
  font-size: $fontSize;
  padding: 0 20px;
  cursor: pointer;
  line-height: 40px;
  display: block;
  text-align: center;
  text-decoration: none;

  @if ($borderColor != none) {
    border: 1px solid $borderColor;

    &[disabled] {
      border-color: lighten($borderColor, 18%);
    }
  } @else {
    border: 0;
  }

  &:hover, &:visited {
    text-decoration: none;
  }

  &[disabled] {
    background-color: lighten($backgroundColor, 18%);
    color: lighten($textColor, 18%);
    cursor: initial;
  }

  &:not([disabled]) {
    &:hover, &:focus {
      color: $textHoverColor;
      border-color: $borderHoverColor;
      background-color: $backgroundHoverColor;
    }
  }

  & + button {
    margin-left: 14px;
  }
}

@mixin widget-button($textColor: $primary-blue,
                       $textHoverColor: $primary-blue-hover,
                       //$width: 50px,
                       $fontSize: 13px) {
  border: none;
  background: none;
  color: $textColor;
  font-weight: 600;
  font-size: $fontSize;
  padding: 0;

  &:not(:disabled):hover {
    color: $textHoverColor;

    * {
      fill: $textHoverColor;
    }
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      cursor: initial;
    }
  }

  & + button {
    margin-left: 16px;
  }
}

.primary {
  @include standard-button($primary-blue, $primary-blue-hover);
}

.secondary {
  @include standard-button(white, white, $primary-blue, $primary-blue-hover, $primary-blue, $primary-blue-hover);
}

.cancel {
  @include standard-button($light-grey, $cancel-button-hover-grey, none, none, $grey-6, $grey-6);
}

.dangerous {
  @include standard-button($text-error, $danger-button-hover-red);
}

.transparent {
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.widget-save {
  @include widget-button();
}

.widget-cancel {
  @include widget-button($grey-6, $grey-5);
}
