// colors
$primary-blue: #006dc9;
$primary-blue-hover: #2359a3;
$primary-blue-disabled: #93b3e5;
$secondary-disabled-blue: #edf4ff;
$disabled-grey: #c8c8c8;
$mid-blue: #2359a3;
$brownish-grey: #666666;
$light-grey: #979797;
$option-blue: #f1f9fd;
$accent-blue: #4ca0ff;
$text-grey: #404040;
$popover-inactive: #8589ac;
$popover-active: #45486c;
$input-field-border: #a7a7a7;
$divider-grey: #d7d7d7;
$greyish-white: #f5f5f5;
$error-red: #da1e27;
$error-background: #fff5f5;
$success-green: #27d397;
$success-background: #f2fcf9;
$information-blue: #00c4ff;
$information-background: #f0f7ff;
$warning-orange: #eb6d12;
$warning-background: #fff6ef;

// common sizes
$cvs-footer-height: 69px;
$cvs-header-height: 75px;
$page-content-width-large: 780px;
$header-content-width-large: 1280px;
$button-width: 152px;
$button-large-height: 40px;
$button-small-height: 32px;
